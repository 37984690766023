import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ["1210px", "1240px"],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brandBlue: {
            500: "#3E5D58",
        },
        brand: {
            darkGreen: '#395353',
            black: '#221F20',
            pink: '#D6BFC3',
            blue: '#335CAF',
            grey: '#DDDDDD',
            lightGrey: '#F3F3F3',
            white: '#FFFFFF',
            darkGrey: '#DEDBD0',
            green: '#506C68',
            lightGreen: '#3EA897',
            paleGreen: '#E9F7E5',
            gold: '#AA8859',
            red: '#DB3B40',
            paleRed: '#FDF1F2',
        },
    },
    fonts: {
        ...theme.fonts,
        heading: 'Neue Metana Next, sans-serif',
        body: 'Faktum, sans-serif',
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const listStyles = css`
    ul:not([class^="DayPicker"]) {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            position: relative;
            line-height: 20px;
            padding-left: 18px;
            margin-bottom: 15px;
            &:before {
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 8px;
                height: 6px;
                width: 6px;
                margin-right: 6px;
                background-color: ${EditorTheme.colors.brand.green};
                border-radius: 9999px;
            }
        }
    }
`;

const table = css`
    .table {
        width: 100%;

        td {
            padding: 0.75rem;
            vertical-align: top;
            border-top: 1px solid ${EditorTheme.colors.brand.darkGrey};
        }
    }
`;

const headings = css`
    h1 {
        font-size: 30px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};

        &.heading2-huge {
            font-size: 60px;
            line-height: 66px;

            @media (max-width: 478px) {
                font-size: 40px;
                line-height: 44px;
            }
        }
    }

    h3 {
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 15px;
        letter-spacing: 0;
        font-weight: 600;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};
    }

    h4 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
        letter-spacing: 0;
        font-weight: 600;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 15px;
        letter-spacing: 0;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};
    }

    h6 {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: ${EditorTheme.fonts.heading};
    }

    strong {
        font-weight: 600;
    }
`;

const focusVisible = css`
    .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
    }
`;

const reactPage = css`
    .ory-row {
        a:not([type="button"])):not(.product-card__link) {
            text-decoration: underline;
            color: ${EditorTheme.colors.brand.darkGreen};
            font-weight: 600;
        }

        p:not(.product-card__name),
        li {
            font-size: 14px;
            line-height: 20px;
        }

        figure.table {
            overflow-x: auto !important;
            overflow-y: auto !important;
        }

        table {
            border: none;
            width: 100%;

            tr {
                color: ${EditorTheme.colors.brand.black};

                &:nth-of-type(even) {
                    background-color: ${EditorTheme.colors.brand.white};
                }

                &:nth-of-type(odd) {
                    background-color: ${EditorTheme.colors.brand.darkGreen};
                    opacity: 0.25;
                }

                &:first-of-type {
                    background-color: ${EditorTheme.colors.brand.darkGreen};
                    color: ${EditorTheme.colors.brand.white};
                    font-weight: 600;
                    opacity: 1;
                }
            }

            td {
                border: none;
                padding: 11px 10px;
                font-size: 14px;
            }
        }

        blockquote {
            font-size: 24px;
            overflow: hidden;
            padding-right: 1.5em;
            padding-left: 1.5em;
            margin-left: 0;
            margin-right: 0;
            font-style: italic;
            border-left: 5px solid ${EditorTheme.colors.brand.darkGreen} !important;

            p:last-of-type {
                margin-bottom: 0;
            }
        }

        ol {
            list-style: none;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 20px;
            position: relative;
            padding-left: 0;
            font-size: "14px";
            counter-reset: list-number;

            li {
                margin-bottom: 15px;
                position: relative;
                display: flex;
                counter-increment: list-number;
                &:before {
                    display: block;
                    content: "";
                    margin-right: 5px;
                    content: counter(list-number) ".";
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        .react-select {
            p {
                margin-bottom: 0px;
            }
        }
    }
`;

const inputs = css`
    input,
    select,
    textarea {
        font-size: 13.6px;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
            color: ${EditorTheme.colors.brand.grey};
        }
    }

    select {
        + div > svg {
            color: ${EditorTheme.colors.brand.blue} !important;
        }
    }

    .react-dropdown-select-content {
        input,
        select,
        textarea {
            &:focus {
                border-color: none !important;
                box-shadow: none !important;
            }
        }
    }

    input[type="radio"] {
        + div {
            transition: border-color 0.2s;
            border-color: ${EditorTheme.colors.brand.grey} !important;
            border-width: 1px !important;
        }

        &:hover {
            + div {
                border-color: ${EditorTheme.colors.brand.grey} !important;
            }
        }
        &:checked {
            + div {
                background-color: ${EditorTheme.colors.brand
                    .blue} !important;
                border-color: ${EditorTheme.colors.brand.blue} !important;
                box-shadow: inset 0px 0px 0px 2px
                    ${EditorTheme.colors.brand.white} !important;

                span {
                    display: none;
                }
            }
        }
    }
`;

const carousel = css`
    .carousel-root {
        width: 100%;
    }

    .carousel {
        ul {
            margin-bottom: 0;
            list-style: none;

            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }
    }

    .carousel .slide {
        text-align: left;
        background: transparent;

        ul {
            margin-bottom: 0;
            li {
                padding-left: 0;
                &:before {
                    content: none;
                }
            }
        }
    }

    .carousel.carousel-slider {
        overflow: visible;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .carousel {
        .control-dots {
            display: flex;
            justify-content: center;
            bottom: -40px;
        }
    }
`;

const oryStyles = css`
    .ory-row {
        a {
            &.content__button {
                text-decoration: none;
            }
        }
    }
`;

// Simple amends to default Algolia styles that prevent us from having to recreate
// an Algolia component within the project just to add some basic styling.
const algolia = css`
    .ais-ClearRefinements-button {
        font-weight: bold;
        text-decoration: underline;
    }
`;

export const GlobalStyles = css`
    @font-face {
        font-family: "Faktum";
        src: url("/fonts/faktum-bold.ttf");
        font-weight: 700;
        font-style: bold;
    }

    @font-face {
        font-family: "Faktum";
        src: url("/fonts/faktum-regular.otf");
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: "Faktum";
        src: url("/fonts/faktum-semibold.ttf");
        font-weight: 600;
        font-style: semibold;
    }

    @font-face {
        font-family: "Neue Metana Next";
        src: url("/fonts/NeueMetanaNext-ExtraBold.otf");
        font-weight: 900;
        font-style: extrabold;
    }

    @font-face {
        font-family: "Neue Metana Next";
        src: url("/fonts/NeueMetanaNext-SemiBold.otf");
        font-weight: 600;
        font-style: semibold;
    }

    ${listStyles}
    ${headings}
    ${carousel}
    ${focusVisible}
    ${reactPage}
    ${oryStyles}
    ${inputs}
    ${table}
    ${algolia}
`;
